/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        // style={{
        //   margin: `0 auto`,
        //   maxWidth: 960,
        //   padding: `0 1.0875rem 1.45rem`,
        // }}
        className="font-gotham"
      >
        <main>{children}</main>
        <footer>
          <div className="w-full flex items-center justify-center bg-brand-astronaut-500 p-8">
            <div className="flex flex-col justify-center items-center text-center md:flex-row uppercase">
              <a
                href="https://ps-snacks.com/pages/faqs"
                className="text-white font-campton tracking-wider mb-4 md:mr-8 md:mb-0"
              >
                FAQ
              </a>
              <a
                href="https://ps-snacks.com/pages/shipping-terms"
                className="text-white font-campton tracking-wider mb-4 md:mr-8 md:mb-0"
              >
                Shipping Terms
              </a>
              <a
                href="https://ps-snacks.com/pages/privacy-policy"
                className="text-white font-campton tracking-wider"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <script
            type="text/javascript"
            async
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Mg5FuQ"
          ></script>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
